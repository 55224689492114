export enum LOCALES {
  MS_BN = "ms-BN",
  EN_BN = "en-BN",
  KM_KH = "km-KH",
  LO_LA = "lo-LA",
  MY_MM = "my-MM",
  CN_MO = "cn-MO",
  VI_VN = "vi-VN",
  ZH_SG = "zh-SG",
  EN_SG = "en-SG",
  EN_PH = "en-PH",
  MS_MY = "ms-MY",
  IN_ID = "in-ID",
  ZH_HK = "zh-HK",
  EN_GB = "en-GB",
  EN_CA = "en-CA",
  EN_AU = "en-AU",
  EN_US = "en-US",
  FR_FR = "fr-FR",
  FR_BE = "fr-BE",
  FR_CH = "fr-CH",
  FR_CA = "fr-CA",
  ES_ES = "es-ES",
  ES_MX = "es-MX",
  PT_PT = "pt-PT",
  PT_BR = "pt-BR",
  ZH_CN = "zh-CN",
  ZH_TW = "zh-TW",
  NB_NO = "nb-NO",
  DA_DK = "da-DK",
  DE_DE = "de-DE",
  DE_CH = "de-CH",
  FI_FI = "fi-FI",
  HU_HU = "hu-HU",
  IT_IT = "it-IT",
  NL_NL = "nl-NL",
  PL_PL = "pl-PL",
  RU_RU = "ru-RU",
  SV_SE = "sv-SE",
  TR_TR = "tr-TR",
  UK_UA = "uk-UA",
  AR_AE = "ar-AE",
  JA_JP = "ja-JP",
  KO_KR = "ko-KR",
  TH_TH = "th-TH",
  CS_CZ = "cs-CZ",
  NN_NO = "nn-NO",
  AR_AF = "ar-AF",
  AR_DZ = "ar-DZ",
  AR_BH = "ar-BH",
  AR_KM = "ar-KM",
  AR_DJ = "ar-DJ",
  AR_PS = "ar-PS",
  AR_JO = "ar-JO",
  AR_KW = "ar-KW",
  AR_LB = "ar-LB",
  AR_LY = "ar-LY",
  AR_MA = "ar-MA",
  AR_OM = "ar-OM",
  AR_PK = "ar-PK",
  AR_QA = "ar-QA",
  AR_SA = "ar-SA",
  AR_SO = "ar-SO",
  AR_EH = "ar-EH",
  AR_TN = "ar-TN",
  AR_EG = "ar-EG",
  AR_YE = "ar-YE",
}

export const COUNTRY_LANGUAGE: Record<string, LOCALES> = {
  FR: LOCALES.FR_FR,
  DE: LOCALES.DE_DE,
  BR: LOCALES.PT_BR,
  PT: LOCALES.PT_BR,
  ES: LOCALES.ES_ES,
  MX: LOCALES.ES_ES,
  US: LOCALES.EN_US,
  KR: LOCALES.KO_KR,
  JP: LOCALES.JA_JP,
  PL: LOCALES.PL_PL,
  IT: LOCALES.IT_IT,
};

export const FALLBACK_LNG = LOCALES.EN_US;

export const SUPPORTED_LANGUAGES = [
  LOCALES.DE_DE,
  LOCALES.EN_US,
  LOCALES.FR_FR,
  LOCALES.PT_BR,
  LOCALES.ES_ES,
  LOCALES.RU_RU,
  LOCALES.KO_KR,
  LOCALES.IT_IT,
  LOCALES.JA_JP,
  LOCALES.PL_PL,
  LOCALES.ZH_TW,
  LOCALES.ZH_CN,
];

export const OVERLAY_LANGUAGES = SUPPORTED_LANGUAGES.map((locale) =>
  locale.toLowerCase()
).join(",");
